import React, { useRef } from 'react';
import { PageProps } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { cannonStyled } from '../theme';
import { CannonBreadcrumbs } from '../atoms';
import { ResponsiveContainer } from '../atoms/Container';
import { useTableOrMobile } from '../hooks';
import RegisterSafeFormBySteps from '../components/sections/RegisterSafeFormBySteps';

interface Props {}

const BreadCrumbsContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '16px' : '75px',
    width: '380px',
  })
);

const MainWrapper = cannonStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: props.isMobile ? '100px 10px' : '50px 10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

export const RegisterYourSafe: React.FC<PageProps> = (props: PageProps) => {
  // HOOKS
  const [isMobile, isTablet] = useTableOrMobile();
  const personalInformationRef = useRef(null) as any;

  // STATE

  // HANLDERS && FUNCTIONS

  // RENDER

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
        <BreadCrumbsContainer isMobile={isMobile}>
          <CannonBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Support', href: '' },
              { title: 'Register your Safe', href: '/register-your-safe' },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          <RegisterSafeFormBySteps/>
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default RegisterYourSafe;
