import React, { useRef, useEffect, useState } from 'react';
import {
  cannonStyled,
  NSH4,
  TWParagraph3,
  useCannonStyletron,
} from '../../../theme';
import SuccessIcon from '../../../assets/success-register-safe-icon.svg';
import { Button } from '../../../atoms';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useTableOrMobile } from '../../../hooks';
import RegisterSurvey from '../RegisterSurvey';

interface IModalInfo {
  insuranceInterested: string;
  averageValue: string;
  commonItem: string;
}

const MainContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
  // height: '60vh',
  paddingTop: isMobile ? 0 : '30px',
}));

const ButtonContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    margin: '10px',
    width: isMobile ? '80%' : 'auto',
  })
);

const ButtonsContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    marginTop: '30px',
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
  })
);

const Modal = cannonStyled('div', ({ show }: { show: boolean }) => ({
  display: show ? 'block' : 'none',
  position: 'fixed',
  zIndex: 1,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: `rgba(0, 0, 0, 0.4)`,
}));

const ModalContent = cannonStyled('div', {
  position: 'relative',
  backgroundColor: '#fefefe',
  margin: 'auto',
  padding: 0,
  borderRadius: '16px',
  border: '1px solid #888',
  width: '80%',
  maxWidth: '1090px',
  boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
  animationName: 'animatetop',
  animationDuration: '0.4s',
});

const ModalBody = cannonStyled('div', {
  padding: '70px',
});

const SuccessStep = () => {
  // HOOKS

  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const [modalOn, setModalOn] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showFetchError, setShowFetchError] = useState(false);
  const [modalInfo, setModalInfo] = useState({} as IModalInfo);
  // EFFECTS

  function showModal() {
    setTimeout(() => {
      setModalOn(true);
    }, 3000);
  }

  useEffect(() => {
    setTimeout(() => {
      scrollTo('#register-your-safe-main-container');
      showModal();
    }, 500);
  }, []);

  const {
    insuranceInterested = '',
    averageValue = '',
    commonItem = '',
  } = modalInfo;

  let modalSelected = document.getElementById('surveyModal');
  window.onclick = function (event) {
    if (event.target == modalSelected) {
      modalSelected.style.display = 'none';
    }
  };

  return (
    <MainContainer isMobile={isMobile}>
      {/* {!isMobile && (
        <Modal show={modalOn} id={'surveyModal'}>
          <ModalContent>
            <ModalBody>
              <RegisterSurvey />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {isMobile && <RegisterSurvey />} */}
      {!isMobile && (
        <>
          <SuccessIcon />
          <NSH4
            $style={{ fontSize: '(7.5vw,56px) !important', paddingTop: '30px' }}
            color={theme.cannonColors.primaryBlue}
            weight={900}
            align='center'
          >
            Safe registered successfully!
          </NSH4>
          <TWParagraph3
            $style={{
              fontSize: 'min(5vw,19px) !important',
              paddingTop: '12px',
            }}
            align='center'
            color={theme.cannonColors.secondarySilverDark}
            weight={400}
          >
            We will confirm your product registration shortly. With your safe
            being registered, we will be able to provide you with better
            customer support if needed.
          </TWParagraph3>
          <ButtonsContainer isMobile={isMobile}>
            <ButtonContainer isMobile={isMobile}>
              <Button.OutlinedButton
                size='compact'
                color='primary'
                fullWidth={isMobile}
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                Back to home
              </Button.OutlinedButton>
            </ButtonContainer>
            <ButtonContainer isMobile={isMobile}>
              <Button.RoundedButton
                size='compact'
                color='primary'
                fullWidth={isMobile}
                onClick={() => {
                  window.location.href = '/user-manuals';
                }}
              >
                Download user's manual
              </Button.RoundedButton>
            </ButtonContainer>
          </ButtonsContainer>
        </>
      )}
    </MainContainer>
  );
};

export default SuccessStep;