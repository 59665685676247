import { ArrowRight, Check } from 'baseui/icon';
import { FILL, Tab, Tabs } from 'baseui/tabs-motion';
import React, { Key, useEffect, useRef, useState } from 'react';
import { Button } from '../../../atoms';
import { useTableOrMobile, useCoreAPIURL } from '../../../hooks';
import { cannonStyled, useCannonStyletron } from '../../../theme';
import AboutYourSafe, { IAboutYourSafe } from './AboutYourSafe';
import { ErrorForm } from '../../../atoms/Cards';
import PersonalInformation, {
  IPersonalInformation,
} from './PersonalInformation';
import Preferences, { IPreferences } from './Preferences';
import SuccessStep from './SuccessStep';

import analyticsCustomEvent from '../../../atoms/js/analyticsCustomEvent';

const MainContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    width: '100%',
    marginTop: isMobile ? '30px' : '50px',
  })
);

export interface IFullInformation {
  information: IPersonalInformation;
  about: IAboutYourSafe;
  preferences: IPreferences;
  [key: string]: any;
}

interface Props {}

const RegisterSafeFormBySteps = () => {
  // HOOKS
  const [isMobile, isTablet] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const personalInformationRef = useRef(null) as any;
  const aboutRef = useRef(null) as any;
  const coreAPI_URL = useCoreAPIURL();

  // STATE
  const [active, setActive] = useState<Key>('information');
  const [showFetchError, setShowFetchError] = useState(false);

  // const [currentStep, setCurrentStep] = useState(1);

  const [steps, setSteps] = useState([
    { title: 'Personal Information', key: 'information', step: 1, done: false },
    { title: 'About your safe', key: 'about', step: 2, done: false },
    { title: 'Preferences', key: 'preferences', step: 3, done: false },
  ]);

  const [fullInformation, setFullInformation] = useState(
    {} as IFullInformation
  );
  // EFFECTS

  // HANLDERS && FUNCTIONS

  async function sendInfo(value: any) {
    const response = await fetch(coreAPI_URL + '/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: value }),
    })
      .then((r) => r.json())
      .then((r) => {
        return r;
      })
      .catch((e) => setShowFetchError(true));
    console.log('Registering your proof of purchase  response:', response);
  }
  async function handleSubmitClicked(value: any) {
    const { about = {} as IAboutYourSafe } = value;
    const { proofOfPurchase = {} as File, serialNumber } = about;
    const myRenamedFile = new File(
      [proofOfPurchase],
      new Date().getTime().toString() +
        '-' +
        serialNumber.toString() +
        '-upload-register-safe-file' +
        '.' +
        proofOfPurchase.name.split('.')[
          proofOfPurchase.name.split('.').length - 1
        ]
    );
    const formData = new FormData();
    formData.append('file', myRenamedFile);
    const response = await fetch(coreAPI_URL + '/upload', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        // Accept: 'multipart/form-data',
      },
      body: formData,
    })
      .then((r) => r.json())
      .then((r) => {
        const { data = {} as { url: string }, message } = r;
        if (message === 'File saved.') {
          const { url = '' } = data;
          value.about.proofOfPurchase = url;
          sendInfo(value);
        } else {
          setShowFetchError(true);
        }
        return r;
      })
      .catch((e) => setShowFetchError(true));
    console.log('respose register file', response);
  }

  function handleChange(value: any, key: string) {
    const fullInformationTmp = { ...fullInformation };
    fullInformationTmp[key] = value;
    setFullInformation(fullInformationTmp);
    if (key === 'preferences') {
      handleSubmitClicked(fullInformationTmp);
    }
  }

  function handleNextStepClicked(value: any, key: string) {
    handleChange(value, key);
    const stepsTmp = steps.map((s) => {
      if (s.key === key) {
        s.done = true;
      }
      return s;
    });
    setSteps(stepsTmp);

    switch (key) {
      case 'information':
        setActive('about');
        break;
      case 'about':
        setActive('preferences');
        break;
      case 'preferences':
        setActive('last');
        break;
    }
  }

  function handleTabsChanged(value: string) {
    if (value === 'about' && active === 'information') {
      personalInformationRef.current.triggerSubmit();
    }
    if (value === 'preferences' && active === 'about') {
      aboutRef.current.triggerSubmit();
    }
    if (
      (active === 'about' || active === 'preferences') &&
      value === 'information'
    ) {
      setActive(value);
    }
    if (active === 'preferences' && value === 'about') {
      setActive(value);
    }
    if (active === 'information' && value === 'preferences') {
      personalInformationRef.current.triggerSubmit();
    }
  }

  // RENDER
  
  const {
    information = {} as IPersonalInformation,
    about = {} as IAboutYourSafe,
  } = fullInformation;

  return (
    <MainContainer isMobile={isMobile} id='register-your-safe-main-container'>
      {showFetchError && <ErrorForm />}
      <Tabs
        activeKey={active}
        onChange={({ activeKey }) => handleTabsChanged(activeKey as string)}
        activateOnFocus
        fill={FILL.fixed}
        overrides={{
          Root: {
            style: () => ({
              minHeight: active !== 'last' ? '60vh' : '15vh',
              // overflowX: 'scroll'
            }),
          },
          TabHighlight: {
            style: () => ({
              backgroundColor: theme.cannonColors.primaryBlue,
              height: '2px',
              '&:hover': {
                background: 'transparent',
              },
              // borderBottom: `2px solid ${theme.cannonColors.secondaryOrange}`
            }),
          },
          TabBorder: {
            style: () => ({
              backgroundColor: theme.cannonColors.secondarySilver,
              borderRadius: '2.5px',
              marginTop: '3px',
              height: '2px',
            }),
          },
        }}
      >
        {steps.map((item) => (
          <Tab
            // disabled={index > currentStep - 1}
            disabled={active === 'last'}
            key={item.key}
            title={item.title}
            artwork={() => (
              <div
                style={
                  item.key === active || item.done
                    ? {
                        borderRadius: '50%',
                        width: isMobile ? '20px' : '30px',
                        color: 'white',
                        height: isMobile ? '20px' : '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: isMobile ? '12px' : '15px',
                        background:
                          item.done === true
                            ? theme.cannonColors.primaryGreenLight
                            : theme.cannonColors.primaryBlue,
                      }
                    : {}
                }
              >
                {item.done ? (
                  <Check size={isMobile ? '18px' : '25px'} />
                ) : (
                  item.step
                )}
              </div>
            )}
            overrides={{
              Tab: {
                style: () => ({
                  background: 'transparent',
                  fontFamily: 'Titillium Web !important',
                  fontSize: 'min(3.4vw, 18px) !important',
                  fontWeight: 700,
                  color: item.done
                    ? theme.cannonColors.primaryGreenDark
                    : active === item.key
                    ? theme.cannonColors.primaryBlue
                    : theme.cannonColors.secondarySilver,
                  '&:active': {
                    fontSize: '18px !important',
                  },
                  '&:hover': {
                    background: 'transparent',
                  },
                }),
              },
            }}
          >
            {item.key === 'information' && (
              <PersonalInformation
                ref={personalInformationRef}
                informationProp={information}
                stepPassed={(value: IPersonalInformation) =>
                  handleNextStepClicked(value, 'information')
                }
              />
            )}
            {item.key === 'about' && (
              <AboutYourSafe
                ref={aboutRef}
                stepPassed={(value: IAboutYourSafe) =>
                  handleNextStepClicked(value, 'about')
                }
                aboutYourSafeProp={about}
              />
            )}
            {item.key === 'preferences' && (
              <Preferences
                stepPassed={(value) => {
                  analyticsCustomEvent('register_safe');
                  handleNextStepClicked(value, 'preferences');
                }} 
              />
            )}
          </Tab>
        ))}
      </Tabs>
      {active === 'last' && <SuccessStep />}
    </MainContainer>
  );
};

export default RegisterSafeFormBySteps;
